<!-- QRCodeDialog.vue -->
<template>
    <v-dialog v-model="localDialog" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline">QR Code</span>
            </v-card-title>
            <v-card-text>
                <div class="pb-4">
                    <app-text size="medium">Scan this QR code to SWAPP In/Out</app-text>
                </div>
                <div class="d-flex justify-center">
                    <qrcode-vue background="#ffffff"
                                foreground="black"
                                level="H"
                                size="300"
                                :value="userFbId"/>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
    name: 'QRCodeDialog',
    
    components: { QrcodeVue },
    
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        userFbId: {
            type: String,
            required: true
        }
    },
    
    data: () => ({
        localDialog: false
    }),
    
    watch: {
        dialog(newVal) {
            this.localDialog = newVal;
        },
        localDialog(newVal) {
            if (!newVal) {
                this.$emit('update:dialog', false);
            }
        }
    },
    
    methods: {
        closeDialog() {
            this.$emit('update:dialog', false);
        }
    }
}
</script>